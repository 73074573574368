import type { MenuItem } from '../types'

const menu: MenuItem[] = [
  {
    icon: 'lightbulb',
    label: 'Light/Dark',
    isDesktopNoLabel: true,
    isToggleLightDark: true,
  },
  {
    icon: 'sign-in-alt-solid',
    label: 'Log in',
    isDesktopNoLabel: true,
    to: '/auth/login',
  },
]

export default menu
