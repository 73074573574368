<script setup lang="ts">
import { useRouter } from 'vue-router'

import menuNavBar from '../config/menuNavBar'
import menuNavBarUnauthed from '../config/menuNavBarUnauthed'
import { useMainStore } from '../stores/main'

import type { MenuClickHandler } from '../types'

const { signOut } = useAuth()
const router = useRouter()

interface HeaderProps {
  hideMenu?: boolean
  isAsideMobileExpanded?: boolean
  isAsideLgActive?: boolean
}

withDefaults(defineProps<HeaderProps>(), {
  hideMenu: false,
  isAsideMobileExpanded: false,
  isAsideLgActive: false,
})

defineEmits(['update:isAsideMobileExpanded', 'update:isAsideLgActive'])

const layoutAsidePadding = 'xl:pl-72'

const styleStore = useStyleStore()
const mainStore = useMainStore()

const menuClick: MenuClickHandler = async (_event, item) => {
  if (item?.isToggleLightDark) {
    styleStore.setDarkMode()
  }

  if (item?.isLogout) {
    await signOut()
    router.push('/')
  }
}

const menu = computed(() => {
  return mainStore.user ? menuNavBar : menuNavBarUnauthed
})
</script>

<template>
  <NavBar
    :menu="menu"
    :class="[layoutAsidePadding, { 'ml-72 lg:ml-0': isAsideMobileExpanded }]"
    @menu-click="menuClick"
  >
    <NavBarItemPlain
      v-if="!hideMenu"
      display="flex lg:hidden"
      @click.prevent="$emit('update:isAsideMobileExpanded', !isAsideMobileExpanded)"
    >
      <BaseIcon :icon="isAsideMobileExpanded ? 'angles-left-solid' : 'angles-right-solid'" size="lg" />
    </NavBarItemPlain>
    <NavBarItemPlain
      v-if="!hideMenu"
      display="hidden lg:flex xl:hidden"
      @click.prevent="$emit('update:isAsideLgActive', true)"
    >
      <BaseIcon icon="bars-solid" size="lg" />
    </NavBarItemPlain>
    <NavBarItemPlain v-if="hideMenu">
      <AtomLogo type="horizontal" />
    </NavBarItemPlain>
    <NavBarItemPlain use-margin>
      <FormControl placeholder="Search (ctrl+k)" ctrl-k-focus transparent borderless />
    </NavBarItemPlain>
  </NavBar>
</template>
