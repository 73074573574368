<template>
  <FooterBar>
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <p class="text-xs text-gray-600 dark:text-gray-400">
          For My Event is a comprehensive event management platform designed to streamline the planning and execution of
          events. Our platform offers a range of features including attendee management, ticketing, and real-time
          analytics to ensure your event's success.
        </p>
      </div>
    </div>
  </FooterBar>
</template>
