<script setup lang="ts">
import { containerMaxW } from '../config/config'

import type { WithMenu, MenuClickEmit, MenuItem } from '../types'

defineProps<WithMenu>()

const emit = defineEmits<MenuClickEmit>()

const menuClick = (event: Event, item: MenuItem) => {
  emit('menu-click', event, item)
}

const isMenuNavBarActive = ref(false)
</script>

<template>
  <nav class="fixed inset-x-0 top-0 z-30 h-14 w-screen bg-pumice-400 transition-position dark:bg-nickel-700 lg:w-auto">
    <div class="flex lg:items-stretch" :class="containerMaxW">
      <div class="flex h-14 flex-1 items-stretch">
        <slot />
      </div>
      <div class="flex h-14 flex-none items-stretch lg:hidden">
        <NavBarItemPlain @click.prevent="isMenuNavBarActive = !isMenuNavBarActive">
          <BaseIcon :icon="isMenuNavBarActive ? 'cross-solid' : 'ellipsis-v-solid'" />
        </NavBarItemPlain>
      </div>
      <ClientOnly>
        <div
          class="absolute left-0 top-14 max-h-screen-menu w-screen overflow-y-auto bg-pumice-400 shadow-lg dark:bg-nickel-700 lg:static lg:flex lg:w-auto lg:overflow-visible lg:shadow-none"
          :class="[isMenuNavBarActive ? 'block' : 'hidden']"
        >
          <NavBarMenuList v-cloak :menu="menu" @menu-click="menuClick" />
        </div>
      </ClientOnly>
    </div>
  </nav>
</template>
