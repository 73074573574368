<script setup lang="ts">
import Icon128 from '../../assets/images/brand/icon/icon_128x128.png'
import Icon16 from '../../assets/images/brand/icon/icon_16x16.png'
import Icon32 from '../../assets/images/brand/icon/icon_32x32.png'
import Icon64 from '../../assets/images/brand/icon/icon_64x64.png'
import fmeVerticalXLarge from '../../assets/images/brand/logo/logo_1600x785.png'
import fmeVerticalSmall from '../../assets/images/brand/logo/logo_200x98.png'
import fmeVerticalMedium from '../../assets/images/brand/logo/logo_400x196.png'
import fmeVerticalLarge from '../../assets/images/brand/logo/logo_800x392.png'
import fmeHorizontalXLarge from '../../assets/images/brand/logo/logo_landscape_1600x290.png'
import fmeHorizontalSmall from '../../assets/images/brand/logo/logo_landscape_200x36.png'
import fmeHorizontalMedium from '../../assets/images/brand/logo/logo_landscape_400x72.png'
import fmeHorizontalLarge from '../../assets/images/brand/logo/logo_landscape_800x145.png'

import type { LogoTypes, LogoSizes } from '../../types'

const logos = {
  icon: {
    small: Icon16,
    medium: Icon32,
    large: Icon64,
    xlarge: Icon128,
  },
  vertical: {
    small: fmeVerticalSmall,
    medium: fmeVerticalMedium,
    large: fmeVerticalLarge,
    xlarge: fmeVerticalXLarge,
  },
  horizontal: {
    small: fmeHorizontalSmall,
    medium: fmeHorizontalMedium,
    large: fmeHorizontalLarge,
    xlarge: fmeHorizontalXLarge,
  },
}

type Props = {
  type: LogoTypes
  size?: LogoSizes
  href?: string | null
}

withDefaults(defineProps<Props>(), {
  type: 'icon',
  size: 'small',
  href: null,
})

const logo = (type: LogoTypes, size: LogoSizes) => {
  return logos[type][size]
}

defineExpose({ logo })
</script>

<template>
  <a v-if="href" :href="href">
    <img :src="logo(type, size)" alt="For My Event Logo" />
  </a>
  <img v-else :src="logo(type, size)" alt="For My Event Logo" />
</template>
