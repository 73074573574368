<script setup>
import AtomLogo from '../components/Atom/Logo.vue'
import { containerMaxW } from '../config/config'

const year = new Date().getFullYear()
</script>

<template>
  <footer class="px-6 py-2" :class="containerMaxW">
    <BaseLevel>
      <div class="text-center md:text-left">
        <slot />
        <b>&copy;{{ year }} For My Event. All rights reserved.</b>
      </div>
      <div class="md:py-2">
        <a href="/">
          <AtomLogo type="vertical" />
        </a>
      </div>
    </BaseLevel>
  </footer>
</template>
