<script setup lang="ts">
import Link from './Atom/Link.vue'

import type { WithMenuItem, MenuClickEmit, MenuItem } from '../types'

const props = withDefaults(defineProps<WithMenuItem>(), {
  parentPath: '',
})

const emit = defineEmits<MenuClickEmit>()

const styleStore = useStyleStore()
const isDropdownActive = ref(false)

const componentClass = computed(() => {
  const base = [
    isDropdownActive.value
      ? tw`${styleStore.navBarItemLabelActiveColorStyle} dark:text-nickel-400`
      : tw`${styleStore.navBarItemLabelStyle} dark:text-white dark:hover:text-nickel-400 ${styleStore.navBarItemLabelHoverStyle}`,
    props.item.menu ? 'lg:py-2 lg:px-3 dark:text-white' : 'py-2 px-3',
  ]

  if (props.item.isDesktopNoLabel) {
    base.push('lg:w-16', 'lg:justify-center')
  }

  return base
})

const mainStore = useMainStore()
const itemLabel = computed(() => (props.item.isCurrentUser ? mainStore.userName : props.item.label))

const menuClick = (event: Event, item: MenuItem) => {
  event.stopPropagation() // Stop event propagation
  emit('menu-click', event, item)

  if (props.item.menu) {
    isDropdownActive.value = !isDropdownActive.value
  }
}

const menuClickDropdown = (event: Event, item: MenuItem) => {
  emit('menu-click', event, item)
}

const root = ref<HTMLElement | null>(null)

const forceClose = (event: Event) => {
  if (root.value && !root.value.contains?.(event.target as Node)) {
    isDropdownActive.value = false
  }
}

if (typeof window !== 'undefined') {
  onMounted(() => {
    if (props.item.menu) {
      window.addEventListener('click', forceClose)
    }
  })

  onBeforeUnmount(() => {
    if (props.item.menu) {
      window.removeEventListener('click', forceClose)
    }
  })
}
</script>

<template>
  <BaseDivider v-if="item.isDivider" nav-bar />
  <Link
    v-else
    ref="root"
    class="relative block cursor-pointer items-center lg:flex w-full lg:w-auto"
    :class="componentClass"
    :to="item.to"
    :href="item.href"
    :target="item.target"
    @click.prevent="(e) => menuClick(e, item)"
  >
    <div
      class="flex items-center w-fit m-auto lg:w-auto lg:m-0"
      :class="{
        'bg-pumice-100 p-3 dark:bg-nickel-800 lg:bg-transparent lg:p-0 lg:dark:bg-transparent': item.menu,
      }"
    >
      <UserAvatarCurrentUser v-if="item.isCurrentUser" class="mr-3 inline-flex h-6 w-6" />
      <BaseIcon v-if="item.icon" :icon="item.icon" class="transition-colors dark:text-white" size="xs" />
      <span
        class="px-2 transition-colors w-full lg:w-auto"
        :class="{ 'lg:hidden': item.isDesktopNoLabel && item.icon }"
      >
        {{ itemLabel }}
      </span>
      <BaseIcon
        v-if="item.menu"
        :icon="isDropdownActive ? 'chevron-up-solid' : 'chevron-down-solid'"
        class="hidden transition-colors lg:inline-flex"
        size="xs"
      />
    </div>
    <div
      v-if="item.menu && item.menu.length > 0"
      class="border-b border-pumice-100 text-sm dark:border-nickel-700 lg:absolute lg:left-0 lg:top-full lg:z-20 lg:min-w-full lg:rounded-lg lg:border lg:bg-pumice lg:shadow-lg lg:dark:bg-nickel-800"
      :class="{ 'lg:hidden': !isDropdownActive, 'lg:block': isDropdownActive }"
    >
      <NavBarMenuList :menu="item.menu" :parent-path="parentPath" @menu-click="menuClickDropdown" />
    </div>
  </Link>
</template>
