<script setup lang="ts">
import { defineProps, withDefaults } from 'vue'

import { useStyleStore } from '../stores/style'

interface Props {
  display?: 'flex' | 'hidden' | string
  useMargin?: boolean
}

withDefaults(defineProps<Props>(), {
  display: 'flex',
  useMargin: false,
})

const styleStore = useStyleStore()
</script>

<template>
  <div
    :class="[
      display,
      styleStore.navBarItemLabelStyle,
      styleStore.navBarItemLabelHoverStyle,
      useMargin ? 'mx-3 my-2' : 'px-3 py-2',
    ]"
    class="cursor-pointer items-center dark:text-pitaya-core dark:hover:text-nickel-400"
  >
    <slot />
  </div>
</template>
