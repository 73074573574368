import type { MenuItem } from '../types'

const menu: MenuItem[] = [
  {
    isCurrentUser: true,
    menu: [
      {
        icon: 'user-solid',
        label: 'My Profile',
        to: '/demo/profile',
      },
      {
        icon: 'cog-solid',
        label: 'Settings',
      },
      {
        icon: 'envelope-solid',
        label: 'Messages',
      },
      {
        isDivider: true,
      },
      {
        icon: 'sign-out-solid', // or 'power-off', 'arrow-circle-o-right'
        label: 'Log Out',
        isLogout: true,
      },
    ],
  },
  {
    icon: 'lightbulb',
    label: 'Light/Dark',
    isDesktopNoLabel: true,
    isToggleLightDark: true,
  },
  {
    icon: 'sign-out-solid', // or 'power-off', 'arrow-circle-o-right'
    label: 'Log out',
    isDesktopNoLabel: true,
    isLogout: true,
  },
]
export default menu
