<script setup lang="ts">
import NavBarItem from '../components/NavBarItem.vue'

import type { MenuClickEmit, MenuItem, WithMenu } from '../types'

withDefaults(defineProps<WithMenu>(), {
  menu: () => [] as MenuItem[],
  parentPath: '',
})

const emit = defineEmits<MenuClickEmit>()

const menuClick = (event: Event, item: MenuItem) => {
  emit('menu-click', event, item)
}
</script>

<template>
  <NavBarItem
    v-for="(item, index) in menu"
    :key="`menu-item${parentPath}-${index}`"
    :item="item"
    :parent-path="`${parentPath}-${index}`"
    @menu-click="menuClick"
  />
</template>
